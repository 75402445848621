import React from 'react'
import "./Extra.css";
import { HiLocationMarker } from 'react-icons/hi';
import CountUp from "react-countup";
import { motion } from 'framer-motion'

function Extra() {
  return (
    <section className="extra-wrapper">
      <div className="padding innerwidth flexCenter extra-container">
        <div className="flexColStart extraileft">
          <div className="extra-title">
            <div className="blueCircle"></div>
            <motion.h1
              initial={{ y: "2rem", opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{
                duration: 5,
                type: "ease-in"
              }}>
              Discover <br /> Most Perfect<br /> Properties</motion.h1>
          </div>
          <div className="flexColStart extra-desc">
            <span className='secondaryText'>Find a Beautiful properties that suite you the best </span>
            <span className='secondaryText'>You will not face any kinde of difficulties in finding the best residence for you </span>

          </div>
          <div className="flexCenter search-bar">
            <HiLocationMarker color="red" size={25}></HiLocationMarker>
            <input type="text" />
            <button className="button">Search</button>
          </div>

          <div className="flexCenter stats">
            <div className="flexColCenter stat">
              <span>
                <CountUp start={800} end={850} duration={5} />
                <span>+</span>{" "}
              </span>
              <span className='secondaryText'>Premium Properties</span>
            </div>

            <div className="flexColCenter stat">
              <span>
                <CountUp start={1000} end={1500} duration={8} />
                <span>+</span>{" "}
              </span>
              <span className='secondaryText'>Happy Customers</span>
            </div>

            <div className="flexColCenter stat">
              <span>
                <CountUp start={1500} end={3500} duration={10} />
                <span>+</span>{" "}
              </span>
              <span className='secondaryText'>Reviews</span>
            </div>
          </div>

        </div>

        <div className="flexCenter extra-right">
          <motion.div
            initial={{ x: "7rem", opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{
              duration: 4,
              type: "ease-in",
            }}
            className="image-container">
            <img src="main.jpg" alt="main-part" />
          </motion.div>
        </div>
      </div>
    </section>
  )
}

export default Extra

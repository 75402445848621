import './App.css';
import Header from './components/Header/Header';
import Extra from './components/Extra/Extra';
import Companies from './components/Companies/Companies';
import Recidencies from './components/Recidencies/Recidencies';
import Value from './components/Value/Value'
import Contact from './components/Contact/Contact'
import GetStarted from './components/GetStarted/GetStarted';
import Footar from './components/Footar/Footar';

function App() {
  return (
    <div className="App">
      <div>
        <Header />
        <Extra />
      </div>
      <Companies />
      <Recidencies />
      <Value />
      <Contact />
      <GetStarted />
      <Footar />
    </div>
  );
}

export default App;

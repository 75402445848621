import React from 'react'
import "./GetStarted.css"

const GetStarted = () => {
  return (
    <section className="g-wrapper">
        <div className="paddings innerWigth g-container">
            <div className="flexColCenter inner-container">
                <span className='primaryText'>Get Starter With RealEstate </span>
                <span className='secondaryText'>Subscribe and find the attractive quotes from us <br/>
                Find your property soon</span>
               
               <button className="button">
                <a href="mailto:maheshvarpe930@gmail.com"> Get Start</a>
               </button>
            </div>
        </div>
    </section>
  )
}

export default GetStarted

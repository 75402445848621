import React from 'react'
import './Contact.css'
import {MdCall, MdMail} from 'react-icons/md';
// import { HiChatBubbleBottomCenter } from 'react-icons/hi2';
// import { SocialIcon } from 'react-social-icons'


const Contact = () => {
  return (
   <section className="c-wrapper">
        <div className="paddings innerWidth flexCenter c-container">
            {/* Left Side */}
            <div className="flexColStart c-left">
                <span className='orangeText'>Our Contact</span>
                <span className='primaryText'>Easy to contact us</span>
                <span className='secondaryText'>This charming bungalow is the perfect place to call home.<br/> 
                With a cozy fireplace, spacious backyard, and plenty of natural light,
                 you’ll never want to leave.</span>
            </div>
            <div className="flexColStart contactModes">
                {/* first row */}
                <div className="flexColStart row">
                    <div className="flexColCenter mode">
                        <div className="flexStart">
                            <div className="flexCenter icon">
                                <MdCall size={25}/>
                                
                            </div>
                            <div className="flexColStart detail">
                                <span className='primaryText'>Call</span>
                                <span className='secondaryText'>+91 7083235728</span>
                            </div>
                        </div>
                        <div className="flexCenter button">
                            Call Now
                        </div>
                    </div>

                    {/* Secong Mode */}
                    <div className="flexColCenter mode">
                        <div className="flexStart">
                            <div className="flexCenter icon">
                                <MdMail size={25}/>
                                
                            </div>
                            <div className="flexColStart detail">
                                <span className='primaryText'>Mail</span>
                                <span className='secondaryText'>maheshvarpe@gmail.com</span>
                            </div>
                        </div>
                        <div className="flexCenter button">
                            Mail Now
                            {/* <a href="mailto:maheshvarpe930@gmail.com"></a> */}
                        </div>
                    </div> 
                </div>
            </div>
            {/* Right Side */}
            <div className="c-right">
                <div className="image-container">
                    <img src="./main4.jpg" alt="" />
                </div>
            </div>
        </div>
   </section>
  )
}

export default Contact
